<template>
  <div>
    <div class="huobu_title">非大商订单</div>
    <!-- 查询非大商表单 -->
    <el-form inline :model="form" ref="form">
      <el-row :gutter="24">
        <el-col :md="5">
          <el-form-item prop="orderCode">
            <el-input
              type="text"
              v-model="form.orderCode"
              placeholder="请输入订单编码"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="5">
          <el-form-item prop="orderStartTime">
            <el-date-picker
              v-model="form.orderStartTime"
              type="datetime"
              placeholder="请选择订单开始时间"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="5">
          <el-form-item prop="orderEndTime">
            <el-date-picker
              v-model="form.orderEndTime"
              type="datetime"
              placeholder="请选择订单结束时间"
              default-time="23:59:59"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="6" style="text-align: right">
          <el-button type="primary" @click="search" size='small' icon='el-icon-search'>查询</el-button>
          <el-button @click="reset('form')" size='small' icon= 'el-icon-refresh'>重置</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-button class="md20" @click="add">设置为大商订单</el-button>
    <!-- 非大商订单表格 -->
    <vxe-table
      class="md20"
      ref="xCheckTable"
      row-id="id"
      @checkbox-all="({ records }) => selectAllEvent({ records })"
      @checkbox-change="({ records }) => selectChangeEvent({ records })"
      :edit-config="{ trigger: 'click', mode: 'cell' }"
      :data="tableData"
    >
      <vxe-table-column type="checkbox" width="50"></vxe-table-column>
      <vxe-table-column field="orderCode" title="订单编码"></vxe-table-column>
      <vxe-table-column field="orderTypeName" title="订单类型"></vxe-table-column>
      <vxe-table-column field="responseBranchName" title="分公司"></vxe-table-column>
      <vxe-table-column field="createDateAll" title="订单时间"></vxe-table-column>
      <vxe-table-column field="businessTypeName" title="业务类型"></vxe-table-column>
      <vxe-table-column field="companyName" title="公司主体"></vxe-table-column>
      <vxe-table-column field="responseName" title="负责人"></vxe-table-column>
    </vxe-table>
    <div class="page-pageination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="tablePage.currentPage"
          :page-sizes="[5, 10, 15, 20, 50, 100, 150, 200]"
          :page-size="tablePage.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tablePage.totalResult"
        >
        </el-pagination>
    </div>
    <div class="huobu_title yibuhuo_title">大商订单</div>
    <!-- 查询大商表单 -->
    <el-form inline :model="formBusiness" ref="formBusiness">
      <el-row :gutter="24">
        <el-col :md="5">
          <el-form-item prop="orderCode">
            <el-input
              type="text"
              v-model="formBusiness.orderCode"
              placeholder="请输入订单编码"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="5">
          <el-form-item prop="orderStartTime">
            <el-date-picker
              v-model="formBusiness.orderStartTime"
              type="datetime"
              placeholder="请选择订单开始时间"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="5">
          <el-form-item prop="orderEndTime">
            <el-date-picker
              v-model="formBusiness.orderEndTime"
              type="datetime"
              placeholder="请选择订单结束时间"
              default-time="23:59:59"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="6" style="text-align: right">
          <el-button type="primary" @click="searchBusiness" size='small' icon='el-icon-search'>查询</el-button>
          <el-button @click="resetBusiness('formBusiness')" size='small' icon= 'el-icon-refresh'>重置</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-button class="md20" @click="tableDel">取消大商订单</el-button>
     <!-- 大商订单表格 -->
    <vxe-table
      class="md20"
      ref="xCheckTable"
      row-id="id"
      @checkbox-all="({ records }) => selectAllEventBusiness({ records })"
      @checkbox-change="({ records }) => selectChangeEventBusiness({ records })"
      :edit-config="{ trigger: 'click', mode: 'cell' }"
      :data="checkTableData"
    >
      <vxe-table-column type="checkbox" width="50"></vxe-table-column>
      <vxe-table-column field="orderCode" title="订单编码"></vxe-table-column>
      <vxe-table-column field="orderTypeName" title="订单类型"></vxe-table-column>
      <vxe-table-column field="responseBranchName" title="分公司"></vxe-table-column>
      <vxe-table-column field="createDateAll" title="订单时间"></vxe-table-column>
      <vxe-table-column field="businessTypeName" title="业务类型"></vxe-table-column>
      <vxe-table-column field="companyName" title="公司主体"></vxe-table-column>
      <vxe-table-column field="responseName" title="负责人"></vxe-table-column>
    </vxe-table>
    <div class="page-pageination">
        <el-pagination
          @size-change="handleSizeChangeBusiness"
          @current-change="handleCurrentChangeBusiness"
          :current-page="tablePageBusiness.currentPage"
          :page-sizes="[5, 10, 15, 20, 50, 100, 150, 200]"
          :page-size="tablePageBusiness.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tablePageBusiness.totalResult"
        >
        </el-pagination>
    </div>
  </div>
</template>
<script>
import request from '@/found/utils/request';

export default {
  props: {
    formConfig: Object,
  },
  data() {
    return {
      tableData: [],
      checkTableData: [],
      selectionList: [],
      selectionListBusiness: [],
      form: {
        orderCode: '',
        orderStartTime: '',
        orderEndTime: '',
      },
      formBusiness: {
        orderCode: '',
        orderStartTime: '',
        orderEndTime: '',
      },
      tablePage: {
        currentPage: 1,
        pageSize: 10,
        totalResult: 0,
      },
      tablePageBusiness: {
        currentPage: 1,
        pageSize: 10,
        totalResult: 0,
      },
    };
  },
  created() {
    console.log(this.formConfig);
    this.getTableList();
    this.getBusinessTableList();
  },
  methods: {
    // 获取非大商列表
    getTableList() {
      const data = {
        custCode: this.formConfig.row.customerCode,
        pageSize: this.tablePage.pageSize,
        pageNum: this.tablePage.currentPage,
        ...this.form,
      };
      request.post('/dms/dms/order/queryOrderByCustCode', data).then((res) => {
        if (res.success) {
          this.tableData = res.result.data;
          this.tablePage.totalResult = res.result.count;
        }
      });
    },
    // 获取大商列表
    getBusinessTableList() {
      const data = {
        custCode: this.formConfig.row.customerCode,
        pageSize: this.tablePageBusiness.pageSize,
        pageNum: this.tablePageBusiness.currentPage,
        ...this.formBusiness,
      };
      request.post('/dms/dmstransitordertransit/list', data).then((res) => {
        if (res.success) {
          this.checkTableData = res.result.data;
          this.tablePageBusiness.totalResult = res.result.count;
        }
      });
    },
    // 设置为大商列表
    add() {
      if (this.selectionList.length === 0) {
        this.$message.error('请先选择非大商订单数据');
        return;
      }
      const orderCodes = this.selectionList.map((v) => v.orderCode);
      const data = {
        custCode: this.formConfig.row.customerCode,
        custName: this.formConfig.row.customerName,
        orderCodes,
      };
      request.post('/dms/dmstransitordertransit/save', data).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '操作成功',
          });
          this.tablePage.currentPage = 1;
          this.tablePageBusiness.currentPage = 1;
          this.getTableList();
          this.getBusinessTableList();
        }
      });
    },
    // 取消大商列表
    tableDel() {
      if (this.selectionListBusiness.length === 0) {
        this.$message.error('请先选择大商订单数据');
        return;
      }
      const ids = this.selectionListBusiness.map((v) => v.id);
      const data = {
        custCode: this.formConfig.row.customerCode,
        ids,
      };
      request.post('/dms/dmstransitordertransit/delete', data).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '操作成功',
          });
          this.tablePage.currentPage = 1;
          this.tablePageBusiness.currentPage = 1;
          this.getTableList();
          this.getBusinessTableList();
        }
      });
    },
    /** @desc 非大商pageSize改变 */
    handleSizeChange(val) {
      this.tablePage.pageSize = val;
      this.getTableList();
    },
    /** @desc 非大商currentPage改变 */
    handleCurrentChange(val) {
      this.tablePage.currentPage = val;
      this.getTableList();
    },
    /** @desc 大商pageSize改变 */
    handleSizeChangeBusiness(val) {
      this.tablePageBusiness.pageSize = val;
      this.getBusinessTableList();
    },
    /** @desc 大商currentPage改变 */
    handleCurrentChangeBusiness(val) {
      this.tablePageBusiness.currentPage = val;
      this.getBusinessTableList();
    },
    // 非大商全选
    selectAllEvent({ records }) {
      this.selectionList = records;
    },
    // 非大商选择
    selectChangeEvent({ records }) {
      this.selectionList = records;
    },
    // 大商全选
    selectAllEventBusiness({ records }) {
      this.selectionListBusiness = records;
    },
    // 大商选择
    selectChangeEventBusiness({ records }) {
      this.selectionListBusiness = records;
    },
    // 非大商搜索
    search() {
      this.tablePage.currentPage = 1;
      this.getTableList();
    },
    // 非大商重置
    reset(formName) {
      this.tablePage.currentPage = 1;
      this.$refs[formName].resetFields();
      this.getTableList();
    },
    // 大商搜索
    searchBusiness() {
      this.tablePageBusiness.currentPage = 1;
      this.getBusinessTableList();
    },
    // 大商重置
    resetBusiness(formName) {
      this.tablePageBusiness.currentPage = 1;
      this.$refs[formName].resetFields();
      this.getBusinessTableList();
    },
  },
};
</script>
<style lang="less" scoped>
  /deep/ .el-form-item, /deep/ .el-form-item__content{
    width: 100%;
  }
  .huobu_title{
    font-size: 16px;
    color: #333;
    padding-bottom: 20px;
  }
  .md20{
    margin-bottom: 20px;
  }
  .yibuhuo_title{
    padding-top: 20px;
    margin-top: 20px;
  }
  .pd20{
    padding-bottom: 20px;
  }
  /deep/ .page-pageination {
    text-align: right;
    padding: 10px 0;
    .el-input {
      display: inline-block !important;
    }
  }
</style>
