<script>
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import dealerForm from './form/detail.vue';
import orderForm from './form/order_form.vue';

export default {
  extends: TablePage,
  components: {
    dealerForm,
    orderForm,
  },
  data() {
    return {
      formConfig: {},
      searchFormData: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  mounted() {
    this.getConfigList('transit_distributor_list');
  },
  methods: {
    modalClick({ val, row }) {
      console.log(val.code);
      if (val.code === 'add_existing_distributors') {
        this.formName = 'dealerForm';
        this.modalConfig.title = '添加已有经销商';
        this.modalConfig.width = '80%';
        this.formConfig.row = row;
        this.formConfig.code = val.code;
        this.openDrawer();
      } else if (val.code === 'enable') {
        if (this.selection.length > 0) {
          this.$confirm('确认要启用吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              const ids = row.map((v) => v.id);
              const params = { ids, ext35: '009' };
              request.post('/mdm/mdmCustomerMsgExtController/enableDisableTransitCustomer', params).then((res) => {
                if (res.success) {
                  this.$message({
                    type: 'success',
                    message: '操作成功',
                  });
                  this.getList();
                }
              });
            });
        } else {
          this.$message('请至少选择一条数据！');
          return false;
        }
      } else if (val.code === 'disable') {
        if (this.selection.length > 0) {
          this.$confirm('确认要禁用吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              const ids = row.map((v) => v.id);
              const params = { ids, ext35: '003' };
              request.post('/mdm/mdmCustomerMsgExtController/enableDisableTransitCustomer', params).then((res) => {
                if (res.success) {
                  this.$message({
                    type: 'success',
                    message: '操作成功',
                  });
                  this.getList();
                }
              });
            });
        } else {
          this.$message('请至少选择一条数据！');
          return false;
        }
      } else if (val.code === 'related_orders') {
        this.formName = 'orderForm';
        this.modalConfig.title = '关联订单';
        this.modalConfig.width = '80%';
        this.formConfig.row = row;
        this.formConfig.code = val.code;
        this.openDrawer();
      }
    },
  },
};
</script>
