import BasePage from '@/found/components/table_page';
import request from '@/found/utils/request';

export default {
  name: 'detail',
  extends: BasePage,
  props: {
    paramsProps: Object,
  },
  data() {
    return {
      requestType: 'POST',
    };
  },
  methods: {
    afterGetList() {
      this.exportParams = this.paramsProps;
      return true;
    },
    // 获取列表数据
    getList() {
      if (this.beforeGetList()) {
        const { searchFormData, tablePage } = this;
        const params = {
          ...this.params,
          ...searchFormData,
          pageNum: tablePage.currentPage,
          pageSize: tablePage.pageSize,
          enableStatus: '009',
          ext34: 'notTransit',
        };
        Object.assign(params, this.paramsProps ? this.paramsProps : {});
        this.tableLoading = true;
        if (this.requestType === 'POST') {
          return request
            .post(
              this.requestUrl,
              { ...params },
              { headers: { functionCode: this.headerFunctionCode } },
            )
            .then((res) => {
              if (res.success) {
                this.tableLoading = false;
                if (res.result.data) {
                  this.tableData = res.result.data;
                  this.tablePage.totalResult = res.result.count;
                } else if (res.result.length >= 0) {
                  this.tableData = res.result;
                } else {
                  this.tableData = [];
                }
                this.afterGetList();
                this.setSelectData();
              }
            });
        }
      }
    },
  },
  created() {
    this.getConfigList('dealer_list');
  },
};
