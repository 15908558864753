<template>
  <div class="home">
    <Page :paramsProps="{}" ref="tableData" />
    <div slot="footer" class="dialog-footer">
      <el-button type="danger" @click="onSubmit" size="small" icon="el-icon-check">提交</el-button>
      <el-button type="danger" @click="closeModal" size="small" icon="el-icon-close">关闭</el-button>
    </div>
  </div>
</template>

<script>
import request from '@/found/utils/request';
import Page from './detail';

export default {
  name: 'detail',
  props: {
    formConfig: Object,
  },
  components: {
    Page,
  },
  methods: {
    // 关闭弹窗
    closeModal() {
      this.$emit('onClose');
    },
    onSubmit() {
      if (this.$refs.tableData.selectRow && this.$refs.tableData.selectRow.length > 0) {
        const ids = this.$refs.tableData.selectRow.map((v) => v.id);
        const url = '/mdm/mdmCustomerMsgExtController/addTransitCustomer';
        const params = { ids };
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>

<style lang='less' scoped>
.home {
  height: calc(100vh - 166px);
}
</style>
